html,
body {
  margin: 0;
  padding: 0;
}
body {
  font-family: sans-serif;
  // background-image: url(../assets/felt.png),
  //   -webkit-radial-gradient(50% 40%, circle farthest-corner, #527c14, #243a0a);
  // background-size: 180px 180px, auto;
  background-attachment: fixed;
  user-select: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
  margin: 0;
  padding: 0;
  background-color: #000;
}

#app {
  // overflow: hidden;
  contain: layout style;
  overflow: visible;
}

.confetti-container {
  /* Assuming you have a container for confetti */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000; /* Set a high z-index */
}

.roulette-wheel {
  float: right;
  width: 320px;
  height: 320px;
  border-radius: 100%;
  background: url(../assets/SI-IRY-BG-BG--.png);
  background-size: 320px 320px;
  shape-outside: circle(190px);
  margin: 0 0 1em 1em;
  box-shadow: 2px 10px 30px rgba(0, 0, 0, 0.4);
  position: relative;
  touch-action: none;
  overflow: visible;

  &.blinking {
    animation: blinks 0.2s steps(2, start) infinite;
  }
}

@keyframes blinks {
  0% {
    background-image: url(../assets/roulette_121.png);
  }
  100% {
    background-image: url(../assets/roulette_12.jpg);
  }
}

@media screen and (max-width: 2640px) {
  .roulette-wheel {
    float: none;
    margin: 1em auto;
  }
}

@media screen and (max-width: 375px) {
  body {
    padding: 0 20px;
  }
  .roulette-wheel {
    float: none;
    left: 30px;
    // margin: 1em 0 0 2em;
  }
}

.roulette-wheel .layer-2,
.roulette-wheel .layer-3,
.roulette-wheel .layer-5,
.ball-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background-size: 280px 280px;
}

.roulette-wheel .layer-2,
.roulette-wheel .layer-4,
.ball-container {
  will-change: transform;
}

.roulette-wheel .layer-2 {
  background-image: url("../assets/SI-center.png");
  background-repeat: no-repeat;
  background-position: center;
  z-index: 5;
  // background-size: cover; /* Ensures the image covers the entire element */
}

.roulette-wheel .layer-3 {
  background-image: url(../assets/SI-1.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100px 100px;
  z-index: 5;
}

.layer-5 {
  background-image: url(../assets/SI-BB-1.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 200px;
  position: absolute;
  top: 8rem;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

// .roulette-wheel .layer-5 {
//   background-image: url(../assets/roulette_5.png);
//   background-repeat: no-repeat;
//   background-position: center;
// }

.roulette-wheel svg {
  position: absolute;
  top: 0;
  left: 0;
}

.roulette-wheel circle {
  cursor: pointer;
  fill: transparent;
}

.roulette-wheel .ball {
  position: absolute;
  width: 14px;
  height: 14px;
  border-radius: 7px;
  background: #fff radial-gradient(circle at 5px 5px, #fff, #444);
  box-shadow: 1px 1px 4px #000;
  transform: translateY(-116px);
  top: 50%;
  left: 50%;
  margin: -7px;
  will-change: transform;
}

.pointer {
  position: absolute;
  top: 60%;
  left: 50%;
  width: 0;
  height: 0;
  margin-top: -190px; /* Half of the wheel height */
  margin-left: -10px; /* Center the triangle horizontally */
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 20px solid red;
  z-index: 10;
}

.bulb {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: rgba(255, 244, 142, 0.8);
  z-index: 1;
}

.bulb.bulb3 {
  top: 40%;
  left: 40%;
  animation: blink 0.2s infinite alternate;
}

.bulb.bulb4 {
  top: 60%;
  left: 60%;
  animation: blink 0.3s infinite alternate;
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
